
<template lang="pug">
  .list
    .listS
      .item(
        :class="[index == activeIndex ? 'actives' : '']",
        v-for="(item, index) in list",
        :key="index",
        @click="activeClick(index)"
      ) {{ item }}
</template>
<script>
import { Tab, Tabs } from "vant";
import "vant/lib/index.css";
export default {
  components: {
    Tab,
    Tabs,
  },
  data() {
    return {};
  },
  props: {
    list: {
      type: Array,
      default: () => {
        return [];
      },
    },
    activeIndex: {
      type: Number,
      default: 0,
    },
    hover: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
  },
  methods: {
    activeClick(index) {
      // this.activeTab = index;
      this.$emit("tabIndex", index);
    },
  },
};
</script>
<style lang="less" scoped>

  .list {
    width: 100%;
    margin: 0 auto;
    overflow-x: scroll;

    &::-webkit-scrollbar {
      width: 30px !important;
      height: 4px;
    }
    @media screen and (max-width: 900px) {
        background: #fff;
        padding: 15px 20px;
        &::-webkit-scrollbar {
          display: none;
        }
    }
    @media screen and (max-width: 750px) {
        background: #fff;
        padding: 0.15rem 0.2rem;
        &::-webkit-scrollbar {
          display: none;
        }
    }
    .listS {
      width: fit-content;
      margin: 0 auto;
      display: flex;
      .item {
        font-size: 14px;
        color: #666666;
        border-bottom: 3px solid #fff;
        margin: 0 5px;
        cursor: pointer;
        word-break: keep-all;
        padding: 5px 15px;
        background: #fff;
        border-radius: 15px;
        border: 1px solid #cccccc;
        @media screen and (max-width: 1200px) {
              font-size: 13px;
              margin: 0 4px;
              padding: 3px 9px;
            }
            @media screen and (max-width: 1000px) {
              font-size: 12px;
              margin: 0 3px;
              padding: 2px 8px;
            }
            @media screen and (max-width: 900px) {
              font-size: 26px;
              margin: 0 4px;
              padding: 2px 15px;
              border-radius: 30px;
              border: none;
              background: transparent;
            }
            @media screen and (max-width: 750px) {
              font-size: 0.26rem;
              margin: 0 0.04rem;
              padding: 0.02rem 0.15rem;
              border-radius: 0.3rem;
              border: none;
              background: transparent;
            }
        &:hover {
          background: #ff6b02 !important;
          border: 1px solid #ff6b02 !important;
          color: #fff !important;
          @media screen and (max-width: 900px) {
            border: none;
            background: transparent;
            color: #666666;
            }
        }
      }
      
      
    }
  }
  .actives {
    background: #ff6b02 !important;
    border: 1px solid #ff6b02 !important;
    color: #fff !important;
  }

</style>