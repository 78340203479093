import { request, config } from '@/utils'

const api = {
    //前端举报选项列表
    report_item_list (params) { 
        return request.post(`${config.apiurl}contribute/forward`,params)
    },
    //前端文章分类
    category_list (params) { 
        return request.post(`${config.apiurl}contribute/forward`,params)
    },
    //前端文章列表
    article_list (params) { 
        return request.post(`${config.apiurl}contribute/forward`,params)
    },
    //前端文章详情
    article_detail (params) { 
        return request.post(`${config.apiurl}contribute/forward`,params)
    },
    //我的文章举报列表
    article_report_list (params) { 
        return request.post(`${config.apiurl}contribute/forward`,params)
    },
    //我的评论举报列表
    comment_report_list (params) { 
        return request.post(`${config.apiurl}contribute/forward`,params)
    },
    //收藏文章
    add_article_collect (params) { 
        return request.post(`${config.apiurl}contribute/forward`,params)
    },
    //文章收藏列表
    collect_article_list (params) { 
        return request.post(`${config.apiurl}contribute/forward`,params)
    },
    //文章转发统计收集
    add_article_share (params) { 
        return request.post(`${config.apiurl}contribute/forward`,params)
    },
    //添加文章举报
    add_article_report (params) { 
        return request.post(`${config.apiurl}contribute/forward`,params)
    },
    //添加评论
    add_comment (params) { 
        return request.post(`${config.apiurl}contribute/forward`,params)
    },
    //添加评论举报
    add_comment_report (params) { 
        return request.post(`${config.apiurl}contribute/forward`,params)
    },
    //点赞文章
    add_article_like (params) { 
        return request.post(`${config.apiurl}contribute/forward`,params)
    },
    //点赞评论
    add_comment_like (params) { 
        return request.post(`${config.apiurl}contribute/forward`,params)
    },
    //评论列表
    comment_list (params) { 
        return request.post(`${config.apiurl}contribute/forward`,params)
    },
    //转发收集
    add_article_sharet (params) { 
        return request.post(`${config.apiurl}contribute/forward`,params)
    },
    //获取微信js-sdk配置
    jsconfig (params) { 
        return request.post(`${config.apiurl}api/weixin/jsconfig`,params)
    },
    // 预览文章
    preview_detail (params) { 
        return request.post(`${config.apiurl}contribute/forward`,params)
    },
    // 浏览量最多文章列表
    most_view_list (params) { 
        return request.post(`${config.apiurl}contribute/forward`,params)
    },
    // 取消收藏
    article_collect_cancel (params) { 
        return request.post(`${config.apiurl}contribute/forward`,params)
    },
    // 识权识美推荐列表
    sqsm_promo_list (params) { 
        return request.post(`${config.apiurl}contribute/forward`,params)
    },
    

}

export default api;