import '@/assets/style/base.less';
import '@/assets/style/common.less';
import Vue from 'vue'
import App from './App.vue'
// import ElementUI from 'element-ui'
// import 'element-ui/lib/theme-chalk/index.css'
// import locale from 'element-ui/lib/locale/lang/zh-CN' // lang i18n
import router from './router'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import locale from 'element-ui/lib/locale/lang/zh-CN' // lang i18n
import './plugins/iview/iview.js'
import { cookie, common } from '@/utils'
import * as axios from '@/utils/request'
import store from '@/store'
import urlConfig from '../config/index.js'
import api from '@/api'
import micros from "./micros/index.js"
import { config } from '@/utils'
// import 'element-ui/lib/theme-chalk/icon.css'
import VueBus from 'vue-bus';
Vue.use(VueBus);
Vue.config.productionTip = false
// set ElementUI lang to EN
Vue.use(ElementUI, { locale })
Vue.use(axios)
Vue.prototype.$api = api;
window.eventBus = new Vue();
window._vue = null;

let paramsData = common.getUrlAllParams()
let redirectData = paramsData
if (!redirectData.token) {
  let tempData = JSON.parse(window.localStorage.getItem('_RedirectData') || "{}")
  redirectData = Object.assign(tempData, redirectData)
}

if (redirectData.token) {
  window.localStorage.setItem('_RedirectData', JSON.stringify(redirectData));
  store.commit('user/setUserInfo', Object.assign(store.state.user.userInfo || {}, { usertype: redirectData.usertype, token: redirectData.token }));
}

console.log('redirectData:', redirectData)

window._global = {
  isLogin: false,
  token: paramsData.token || redirectData.token,
  route: '',
  userInfo: {},
  auths: [],
  env: process.env.NODE_ENV == "production"
}

router.beforeEach((to, from, next) => {
  // 其他人写的接口需要获取token
  //  && store.state.user.loginStatus == false
  let loginUrl = ''
  if (document.body.clientWidth > 900) {
    loginUrl = config.yun_domain;
  } else {
    loginUrl = config.loginUrlWap;
  }
  if (paramsData.token) {
    paramsData = {}
    api.user.userInfo({ token: redirectData.token }).then(res => {
      if (res.status_code !== 0) return
      console.log('res.obj:', res.obj)
      store.commit('user/setUserInfo', Object.assign({}, res.obj, { usertype: res.obj.user_type, token: redirectData.token }));
    })
    api.otherApi.user.login(redirectData).then(res => {
      store.commit('user/changeLoginStatus', true);
      cookie.setCookie('token', res.data.threeToken)
      window.localStorage.setItem('USER__TOKEN', res.data.token)
      window.localStorage.setItem('_RedirectData', JSON.stringify(Object.assign(redirectData, { token: res.data.threeToken })));
    }).finally(() => {
      let queryData = JSON.parse(window.localStorage.getItem('_UrlParamsData') || "{}")
      next({ path: to.path, query: queryData })
      window.sessionStorage.setItem("SET_LOGIN_REDIRECT_URL", urlConfig.yun_domain + '/acctlogin/?referer_url=' + location.href)
    })
  } else {
    next()
    window.sessionStorage.setItem("SET_LOGIN_REDIRECT_URL", loginUrl + '/acctlogin/?referer_url=' + location.href)
  }
})
siteInit();
micros.start()
// 全局初始化
function siteInit() {
  window._vue = new Vue({
    router,
    store,
    render: h => h(App),
    created() {
      // 实例创建完成后被立即调用rem换算方法,解决内容闪现问题
      //移动端使用
      setRem();
      window.addEventListener('resize', setRem);//浏览器窗口大小改变时调用rem换算方法
    }
  }).$mount('#app')
}


// 手机端
function setRem() {
  if (document.body.clientWidth <= 900) {
    var whdef = 100 / 750; // 表示750的设计图,使用100PX的默认值
    var rem = 750 * whdef; // 以默认比例值乘以当前窗口宽度,得到该宽度下的相应FONT-SIZE值
    document.getElementsByTagName('html')[0].style.fontSize = rem + 'px';
  }
  if (document.body.clientWidth <= 750) {
    var whdef = 100 / 750; // 表示750的设计图,使用100PX的默认值
    var bodyWidth = document.body.clientWidth; // 当前窗口的宽度
    var rem = bodyWidth * whdef; // 以默认比例值乘以当前窗口宽度,得到该宽度下的相应FONT-SIZE值
    document.getElementsByTagName('html')[0].style.fontSize = rem + 'px';
  }
}

