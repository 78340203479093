
<template lang="pug">
.list()
    .ite(v-if="sbname == '商标'")
        .listS(v-for="(item, index) in list",:key="index",)
            .shang(v-if="sbname == '商标'"  @click="activeClick(zcjyTabIndex+','+item.id)") {{ item.name }}
    .ita(v-else)
        .listS(v-for="(item, index) in list",:key="index",)
            .item
                .tit( @click="activeClick(zcjyTabIndex+','+item.id)") {{ item.name }}
                .foot
                    .info( v-for="(ites,i) in item.children"  )
                        p( @click="activeClicks(zcjyTabIndex+','+item.id+','+ites.id)") {{ites.name}}
</template>
<script>
import { Tab, Tabs } from "vant";
import "vant/lib/index.css";
export default {
  components: {
    Tab,
    Tabs,
  },
  data() {
    return {
        activeIndex1:0,
    };
  },
  props: {
    list: {
      type: Array,
      default: () => {
        return [];
      },
    },
    sbname: {
      type: String,

    },
    zcjyTabIndex:{
       type: Array,
        default: () => {
            return [];
        },

    },

    hover: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
  },

  created(){


  },
  methods: {
    activeClick(e,v) {

        this.$router.push({
            path:"/commodity/buy",
            query:{
                productCategoryId:e,
                name:v,
            }
        })
        // // alert(index)
    //     this.activeIndex1=0
    //     this.activeIndex=v
    //   this.$emit("tabIndex", v);
    },
     activeClicks(e) {
        this.$router.push({
            path:"/commodity/buy",
            query:{
                productCategoryId:e,

            }
        })
        // alert(index)
    //     this.activeIndex=0
    //     this.activeIndex1=v
    //   this.$emit("tabIndex", v);
    },
  },
};
</script>
<style lang="less" scoped>
.list {
  width: 100%;
  margin: 0 auto;
  overflow-x: scroll;

  &::-webkit-scrollbar {
    width: 30px !important;
    height: 4px;
  }

  @media screen and (max-width: 900px) {
    background: #fff;
    padding: 15px 20px;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  @media screen and (max-width: 750px) {
    background: #fff;
    padding: 0.15rem 0.2rem;

    &::-webkit-scrollbar {
      display: none;
    }
  }
    .ite{
        display: flex;
        flex-wrap: wrap;
        gap:0.16rem;


        .listS {
            @media screen and (max-width: 800px) {
                .shang {
                    width: 180px;
                }

            }
             @media screen and (max-width: 700px) {
                .shang {
                    width: 160px;
                }

            }
            @media screen and (max-width: 600px) {
                .shang {
                    width: 110px;
                }

            }
            @media screen and (max-width: 550px) {
                .shang {
                    width: 130px;
                }

            }
            @media screen and (max-width: 450px) {
                .shang {
                    width: 110px;
                }

            }




            .shang{
                color: #333333;
                font-size: 0.26rem;
            }
            .shang:hover{
                text-decoration:underline;
                color: #FF6B02 !important;
                font-weight: bold;

            }
        }

    }
    .ita{
        .listS {


            .item {
                border-bottom: 3px solid #fff;
                // margin: 0 5px;
                margin-bottom: 10px;
                cursor: pointer;
                word-break: keep-all;
                padding: 15px 15px;
                background: #fff;
                border-radius: 15px;
                border: 1px solid #cccccc;
                display: flex;
                gap: 20px;
                .tit{
                    font-size: 26px;
                    color: #FF6B02;
                    // min-width: 120px;


                }


                .tit:hover{
                    text-decoration:underline;
                    color: #FF6B02 !important;
                    font-weight: bold;

                }


            @media screen and (max-width: 1200px) {
                font-size: 13px;
                margin: 0 4px;
                padding: 3px 9px;
            }

            @media screen and (max-width: 1000px) {
                font-size: 12px;
                margin: 0 3px;
                padding: 2px 8px;
            }

            @media screen and (max-width: 900px) {
                font-size: 26px;
                margin: 0 4px;
                padding: 10px 15px;
                border-radius: 30px;
                border: none;
                background: transparent;

            }

            @media screen and (max-width: 750px) {
                font-size: 0.26rem;
                margin-bottom: 0.2rem;
                padding: 0.02rem 0.15rem;
                border-radius: 0.3rem;
                border: none;
                background: transparent;
                .tit{
                    font-size: 0.26rem;
                    color: #FF6B02;
                    // min-width: 182px;

                }

            }



            .foot{
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                text-align: left;
                gap: 15px;

                .info{
                    display: flex;
                    // gap: 30px;
                    p{
                        color: #333333;
                        font-size: 0.26rem;
                        // padding: 0px 15px 10px 0 ;
                    }
                    p:hover{
                        text-decoration:underline;
                        color: #FF6B02 !important;
                        font-weight: bold;

                    }



                }

            }

            }


        }

    }

}

 .actives {
    // .tit{
       text-decoration:underline;
        color: #FF6B02 !important;
        font-weight: bold;

    // }

 }
  .activess {
    // .tit{
       text-decoration:underline;
        color: #FF6B02 !important;
        font-weight: bold;

    // }

 }
</style>